import { Observable } from 'rxjs';
import { AppDataService } from './../app-data.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  constructor(private globalData: AppDataService,  private httpClient: HttpClient
  ) {
    // this.service.init.subscribe();
  }

  appDefaults: any = {
    integrations: {
      defaultFilterValues: {
        status: ['ACTIVE'] // actually being used now
      }
    },
    // Other more generic preferences go in their own sections
    layout: {
      minimizeDetailPanelsByDefault: true,
      modalCloseConfirmation: true
    },
  };

  service = {
    mergedPreferences: undefined,
    originalUserPrefs: undefined,
    getUserPreferences: () => {
      return this.service.mergedPreferences;
    },

    getPreference: (path: string): any => {
      if (path) {
        const pathArray = path.split('.');
        return this.getPathRecursive(pathArray, this.service.mergedPreferences);
      } else {
        // console.debug('UserPreferences - Bad path: ' + path);
        return null;
      }
    },

    init: new Observable((observer: any) => {
      this.getData.subscribe((data: any) => {
        const storedUserPreferences = localStorage.getItem('userPreferences');
        if (storedUserPreferences) {
          this.service.originalUserPrefs = JSON.parse(storedUserPreferences);
        } else if (data.partyExtensions) {
          const originalPrefs = data.partyExtensions.filter((ext: any) => {
            return ext.name === 'UserPreferences' && !ext.relatedPartyId;
          });
          if (originalPrefs !== null && originalPrefs.length > 0) {
            this.service.originalUserPrefs = originalPrefs[0].json;
          }
          if (this.service.originalUserPrefs) {
            localStorage.setItem('userPreferences', JSON.stringify(this.service.originalUserPrefs));
          }
        };
        this.refreshPreferences(data);
        observer.next();
        observer.complete()
      });
    }),

    handleLogout: (): void => {
      // clear stored preferences on logut and token expiration
      // since the preferences would be fresh in the jwt
      localStorage.removeItem('userPreferences');
      this.service.originalUserPrefs = undefined;
    }
  };

  private getData = new Observable((observer: any) => {
    const jwth = new JwtHelperService();
    const token = localStorage.getItem('token');
    let validToken = false;
    const data: any = {};

    try {
      validToken = !jwth.isTokenExpired(token);
    } catch (e) {
      console.log('Bad token ' + token);
    }

    if (validToken) {
      const decoded = jwth.decodeToken(token);

      if (decoded.partyExtensions) {
        data.partyExtensions = decoded.partyExtensions.map(JSON.parse).map((partyExtension: any) => {
          partyExtension.json = JSON.parse(partyExtension.json);
          return partyExtension;
        });
      } else {
        data.partyExtensions = [];
      }

      let ajaxSettings = {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      };

      this.httpClient
        .get(this.globalData.baseUrl + 'configuration/DEFAULT_CHEETAH_PREFS', ajaxSettings)
        .subscribe((response: any) => {
          if (response.success) {
            if (response.entity.length > 0) {
              try {
                data.clientDefaultPrefs = JSON.parse(response.entity[0].cfgValue);
                observer.next(data);
              } catch (e) {
                console.log('Bad DEFAULT_CHEETAH_PREFS value. Error: ' + e);
              }
            } else {
              observer.next(data);
            }
          }
        });
    }
  });

  private refreshPreferences(data: any): void {
    const refreshedPreferences = $.extend(
      true,
      {},
      this.appDefaults,
      data.clientDefaultPrefs ? data.clientDefaultPrefs : {},
      this.service.originalUserPrefs
      );
    this.service.mergedPreferences = refreshedPreferences;
  }

  private getPathRecursive(remainingPath: any, currentObject: any): any {
    const target = remainingPath.shift();

    if (typeof target === 'undefined') {
      // something ain't right
      console.log('UserPreferences - getPathRecursive target is undefined: ' + remainingPath);
      return undefined;
    } else {
      // figure out what object we're getting if any
      let object: any;
      if (/^[0-9]*$/.test(target) && currentObject) {
        // check if we have a number
        object = currentObject[parseInt(target, 10)];
      } else if (!/[^\w|]|[0-9]/.test(target) && currentObject) {
        // or a string
        object = currentObject[target];
      } else {
        console.log('getPathRecursive - path contains invalid characters: ' + target);
        object = undefined;
      }

      // decide if we should return
      if (remainingPath.length === 0 || object === undefined) {
        // if the path has been consumed OR we've hit an invalid branch of the path
        // go ahead and return what we've got
        return object;
      } else {
        return this.getPathRecursive(remainingPath, object);
      }
    }
  }
}
